const LOCAL = {
	BASE_URL: 'http://localhost:3000',
	API_BASE_URL: 'http://localhost:5001',

	FIREBASE_API_KEY: 'AIzaSyAjkEyGPx7Q-rub_0K-OvQfEqZUYroIFYc',
	FIREBASE_AUTH_DOMAIN: 'ohm-dev.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://ohm-dev.firebaseio.com',
	FIREBASE_PROJECT_ID: 'ohm-dev',
	FIREBASE_STORAGE_BUCKET: 'ohm-dev.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '244287290373',
	FIREBASE_APP_ID: '1:244287290373:web:c159c9248ee9f85dd4f06c',

	GOOGLE_API_KEY: 'AIzaSyDz8QiWesD5sk5p4vb8P1Ruhp4inBuq2ao',
	// https://console.developers.google.com/apis/credentials?project=ohm-dev-276618
	// https://console.cloud.google.com/google/maps-apis/api-list?project=ohm-dev-276618
}

const DEVELOPMENT = {
	BASE_URL: 'https://d329niwucymzox.cloudfront.net',
	API_BASE_URL: 'https://yaf8gsxgl2.execute-api.us-east-1.amazonaws.com/dev',

	FIREBASE_API_KEY: 'AIzaSyAjkEyGPx7Q-rub_0K-OvQfEqZUYroIFYc',
	FIREBASE_AUTH_DOMAIN: 'ohm-dev.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://ohm-dev.firebaseio.com',
	FIREBASE_PROJECT_ID: 'ohm-dev',
	FIREBASE_STORAGE_BUCKET: 'ohm-dev.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '244287290373',
	FIREBASE_APP_ID: '1:244287290373:web:c159c9248ee9f85dd4f06c',

	GOOGLE_API_KEY: 'AIzaSyDz8QiWesD5sk5p4vb8P1Ruhp4inBuq2ao',
	// https://console.developers.google.com/apis/credentials?project=ohm-dev-276618
}

const PRODUCTION = {
	BASE_URL: 'https://www.mt.openheartmagic.org',
	API_BASE_URL: 'https://l4a8b1p1h9.execute-api.us-east-1.amazonaws.com/prod',
	// BASE_URL: 'http://localhost:3000',
	// API_BASE_URL: 'http://localhost:5001',

	FIREBASE_API_KEY: 'AIzaSyArQMBw_j44gZF3jWyPodMTeBR-teBX6u4',
	FIREBASE_AUTH_DOMAIN: 'ohm-prod.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://ohm-prod.firebaseio.com',
	FIREBASE_PROJECT_ID: 'ohm-prod',
	FIREBASE_STORAGE_BUCKET: 'ohm-prod.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '405001347857',
	FIREBASE_APP_ID: '1:405001347857:web:0930ffebb9b197841522b0',

	GOOGLE_API_KEY: 'AIzaSyBajRVZOcBXiMHUue2lNbBfrItaupRtDws',
	// https://console.developers.google.com/apis/credentials?project=ohm-prod
}

function determine_dev() {
	return (
		window.location.origin.includes('d329niwucymzox.cloudfront.net') ||
		process.env.NODE_ENV === 'development'
	)
}

export default {
	dev: determine_dev(),
	apiDebugging: determine_dev(),

	VERSION: '0.1.0',

	GOOGLE_ANALYTICS_KEY: '',
	EMAIL_API_ROUTE: 'https://ov7qju62dl.execute-api.us-east-1.amazonaws.com/development/sendMail',

	// ...DEVELOPMENT,
	// ...PRODUCTION,
	// ...LOCAL,
	...(window.location.origin.includes('d329niwucymzox.cloudfront.net')
		? DEVELOPMENT
		: process.env.NODE_ENV === 'development'
		? LOCAL
		: PRODUCTION),
}
