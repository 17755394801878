import { Filter } from 'react-table'

export const customTableFilter = (filter: Filter, row: any) => {
	const id = filter.pivotId || filter.id
	const rowValue = row[id]

	if (rowValue !== null && (typeof rowValue === 'string' || typeof rowValue === 'number')) {
		return row[id] !== undefined
			? String(rowValue).toLowerCase().includes(filter.value.toLowerCase())
			: true
	}
}

/**
 * This will match search characters if they appear in
 * order in the string e.g. 'Robinson' will match Robinson but so will 'Rbnson'.
 * The main purpose is to deal with null bytes (null byte is \x00 or where
 * latin1String.charCodeAt(i) === 0) popping up in migrated data coming from
 * the MySQL DB from the old platform, which was encoded in Latin1 - during
 * the migration there were issues converting to UTF-8.
 **/
export const fuzzyMatchFilter = (filter: Filter, row: any) => {
	const id = filter.pivotId || filter.id
	let rowValue = (String(row[id]) || '').toLowerCase()
	const filterValue = (filter?.value || '').toLowerCase()

	for (let i = 0; i < filterValue.length; i++) {
		let ind = rowValue.indexOf(filterValue.charAt(i))
		if (ind > -1) {
			// console.log(`found char '${filterValue.charAt(i)}' in string '${rowValue}'`)
			rowValue = rowValue.slice(ind + 1, rowValue.length)
			continue
		} else {
			return false
		}
	}
	return true
}

export const filterDateColumn = (dateFormatFunction: any, filter: any, row: any) => {
	const id = filter.pivotId || filter.id
	const rowValue = row[id]

	if (rowValue) {
		const dateString = dateFormatFunction(rowValue)
		return dateString.toLowerCase().includes(filter.value.toLowerCase())
	}
}
