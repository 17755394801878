export const theme = {
	primary: '#ef3825',
	primaryLight: '#fbd7d3',
	gray: '#2d2b2c',
	lightGray: '#f2f2f2',
	darkGray: '#2f2f2f',
	red: '#ef3825',
	lightRed: '#fbd7d3',
	teal: '#71c6bf',
	lightTeal: '#cfe5e3',
	black: '#191414',
}
export const globalStyles = `
	body {
		margin: 0px;
		padding: 0px;
		font-family: 'Futura Round Demi', sans-serif;
		color: #1C1C1C;
	}
	.TEButton  {
		color: white;
		font-family: 'Futura Round Demi', sans-serif;
		background-color: ${theme.red};
		font-size: 12px;
		text-transform: uppercase;
	}
	.TEPopupFormLeftButton {
		background-color: ${theme.lightRed};
	}
`
