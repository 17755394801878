import Geocode from 'react-geocode'
import settings from 'config/settings'

export const generateRandomCode = (length = 2) => {
	let code = ''
	for (let i = 0; i < length; i++) {
		code += s4()
	}
	return code
}

function s4() {
	return Math.floor((1 + Math.random()) * 0x10000)
		.toString(16)
		.substring(1)
}

export const downloadCSV = (csvString: string, fileName: string) => {
	const blob = new Blob([csvString])
	if (
		window.navigator &&
		window.navigator.msSaveOrOpenBlob &&
		window.navigator.msSaveOrOpenBlob(blob)
	)
		// IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
		window.navigator.msSaveBlob(blob, `${fileName}.csv`)
	else {
		var a = window.document.createElement('a')
		a.href = window.URL.createObjectURL(blob)
		a.download = `${fileName}.csv`
		document.body.appendChild(a)
		a.click() // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
		document.body.removeChild(a)
	}
}

export const printPartOfPage = (elementId: string, uniqueIframeId: string) => {
	const content = document.getElementById(elementId)
	console.log(content)

	if (!content) {
		console.error('No Element Selected', content)
		return
	}

	let pri
	if (document.getElementById(uniqueIframeId)) {
		pri =
			document &&
			document.getElementById(uniqueIframeId) &&
			// @ts-ignore
			document.getElementById(uniqueIframeId).contentWindow
	} else {
		const iframe = document.createElement('iframe')
		iframe.setAttribute('title', uniqueIframeId)
		iframe.setAttribute('id', uniqueIframeId)
		iframe.setAttribute('style', 'height: 0px; width: 0px; position: absolute;')
		document.body.appendChild(iframe)
		pri = iframe.contentWindow
	}
	pri.document.open()
	pri.document.write(content.innerHTML)
	pri.document.close()
	pri.focus()
	pri.print()
}

export const getLatLngFromAddressOrZip = async (input: string | number) => {
	Geocode.setApiKey(settings.GOOGLE_API_KEY)
	const r = await Geocode.fromAddress(String(input))
	return r.results[0].geometry.location
}
