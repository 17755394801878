import { programsArray, userPermissionArray } from 'config/localData'

// should probably rewrite using Array.some or Array.find
export const transformPermissions = (data: string[] | undefined) => {
	let rv: {
		key: string
		value: string
	}[] = []
	data?.forEach((el) => {
		userPermissionArray.forEach((permission) => {
			if (permission.key === el) {
				rv.push(permission)
				return
			}
		})
	})
	return rv
}

// should probably rewrite using Array.some or Array.find
export const transformPrograms = (data: string[] | undefined) => {
	let rv: {
		key: string
		value: string
	}[] = []
	data?.forEach((el) => {
		programsArray.forEach((program) => {
			if (program.key === el) {
				rv.push(program)
				return
			}
		})
	})
	return rv
}
