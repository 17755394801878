import React, { createContext, useState, ReactNode, useEffect } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

import { collectionToDataArray, docToDataObjectUid, filterInactiveData } from 'helpers'
import { UserModel, Hospital, Region } from 'interfaces'

interface ContextProps {
	user: firebase.User | null
	setUser: React.Dispatch<React.SetStateAction<firebase.User | null>>
	userLoaded: boolean
	setUserLoaded: React.Dispatch<React.SetStateAction<boolean>>
	hospitals: Hospital[] | null
	setHospitals: React.Dispatch<React.SetStateAction<Hospital[] | null>>
	hospitalsLoaded: boolean
	setHospitalsLoaded: React.Dispatch<React.SetStateAction<boolean>>
	regions: Region[] | null
	setRegions: React.Dispatch<React.SetStateAction<Region[] | null>>
	regionsLoaded: boolean
	setRegionsLoaded: React.Dispatch<React.SetStateAction<boolean>>
	appVersions: any | null
	setAppVersions: React.Dispatch<React.SetStateAction<any | null>>
	appVersionsLoaded: boolean
	setAppVersionsLoaded: React.Dispatch<React.SetStateAction<boolean>>
	userSignedIn: boolean
	setUserSignedIn: React.Dispatch<React.SetStateAction<boolean>>
	userModel: UserModel | undefined
	setUserModel: React.Dispatch<React.SetStateAction<UserModel | undefined>>
	userModelLoaded: boolean
	setUserModelLoaded: React.Dispatch<React.SetStateAction<boolean>>
}
export const AppContext = createContext<ContextProps>({
	user: null,
	setUser: () => {},
	userLoaded: false,
	setUserLoaded: () => {},
	hospitals: null,
	setHospitals: () => {},
	hospitalsLoaded: false,
	setHospitalsLoaded: () => {},
	regions: null,
	setRegions: () => {},
	regionsLoaded: false,
	setRegionsLoaded: () => {},
	userSignedIn: false,
	setUserSignedIn: () => {},
	userModel: undefined,
	setUserModel: () => {},
	userModelLoaded: false,
	setUserModelLoaded: () => {},
	appVersions: null,
	setAppVersions: () => {},
	appVersionsLoaded: false,
	setAppVersionsLoaded: () => {},
})

export const AppProvider = (props: { children: ReactNode }) => {
	const [user, setUser] = useState<firebase.User | null>(null)
	const [userLoaded, setUserLoaded] = useState(false)
	const [hospitals, setHospitals] = useState<Hospital[] | null>(null)
	const [hospitalsLoaded, setHospitalsLoaded] = useState(false)
	const [regions, setRegions] = useState<Region[] | null>([])
	const [regionsLoaded, setRegionsLoaded] = useState(false)
	const [userSignedIn, setUserSignedIn] = useState(false)
	const [userModel, setUserModel] = useState<UserModel | undefined>(undefined)
	const [userModelLoaded, setUserModelLoaded] = useState(false)
	const [appVersions, setAppVersions] = useState<any | null>(null)
	const [appVersionsLoaded, setAppVersionsLoaded] = useState(false)

	useEffect(() => {
		firebase.auth().onAuthStateChanged(
			(user) => {
				if (user) {
					setUser(user)
					setUserLoaded(true)
				} else {
					setUser(null)
					setUserLoaded(true)
				}
			},
			(e) => {
				console.error(e)
				setUser(null)
				setUserLoaded(true)
			},
			() => console.log('onAuthStateChanged Completed'),
		)
	}, [])

	useEffect(() => {
		let userWatcher: () => void
		if (user && user.uid) {
			userWatcher = firebase
				.firestore()
				.collection('User')
				.doc(user.uid)
				.onSnapshot(
					(snapshot) => {
						setUserModel(docToDataObjectUid(snapshot))
						setUserModelLoaded(true)
					},
					(e) => {
						console.log(e)
						setUserModel(undefined)
						setUserModelLoaded(true)
					},
				)
		} else if (userLoaded) {
			// not logged in
			setUserModel(undefined)
			setUserModelLoaded(true)
		}

		return () => {
			if (userWatcher) {
				userWatcher()
			}
			setUserModel(undefined)
			setUserModelLoaded(false)
		}
	}, [user, userLoaded])

	useEffect(() => {
		let hospitalsWatcher: () => void
		hospitalsWatcher = firebase
			.firestore()
			.collection('Hospital')
			.onSnapshot(
				(snapshot) => {
					setHospitals(collectionToDataArray(snapshot, filterInactiveData))
					setHospitalsLoaded(true)
				},
				(e) => {
					console.error(e)
					setHospitals([])
					setHospitalsLoaded(true)
				},
			)
		return () => {
			if (hospitalsWatcher) {
				hospitalsWatcher()
			}
			setHospitals([])
			setHospitalsLoaded(false)
		}
	}, [])

	useEffect(() => {
		const regionsWatcher = firebase
			.firestore()
			.collection('Region')
			.onSnapshot(
				(snapshot) => {
					setRegions(collectionToDataArray(snapshot, filterInactiveData))
					setRegionsLoaded(true)
				},
				(e) => {
					console.error(e)
					setRegions([])
					setRegionsLoaded(true)
				},
			)

		return () => {
			regionsWatcher()
			setRegions([])
			setRegionsLoaded(false)
		}
	}, [])

	useEffect(() => {
		if (userModel && userModel.status === 3) {
			console.log('Account inactive - logging out')
			firebase.auth().signOut()
		}
	}, [userModel])

	return (
		<AppContext.Provider
			value={{
				user,
				setUser,
				userLoaded,
				setUserLoaded,
				hospitals,
				setHospitals,
				hospitalsLoaded,
				setHospitalsLoaded,
				regions,
				setRegions,
				regionsLoaded,
				setRegionsLoaded,
				userSignedIn,
				setUserSignedIn,
				userModel,
				setUserModel,
				userModelLoaded,
				setUserModelLoaded,
				appVersions,
				setAppVersions,
				appVersionsLoaded,
				setAppVersionsLoaded,
			}}
		>
			{props.children}
		</AppContext.Provider>
	)
}
