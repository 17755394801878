import React, { createContext, useState } from 'react'

interface MagicMeterActive {
	magicMeterActive: boolean
	setMagicMeterActive: React.Dispatch<React.SetStateAction<boolean>>
}
export const MagicMeterContext = createContext<MagicMeterActive>({
	magicMeterActive: false,
	setMagicMeterActive: () => {},
})

export const MagicMeterActiveProvider: React.FC = (props) => {
	const [magicMeterActive, setMagicMeterActive] = useState(false)

	return (
		<MagicMeterContext.Provider value={{ magicMeterActive, setMagicMeterActive }}>
			{props.children}
		</MagicMeterContext.Provider>
	)
}
