import firebase from 'firebase'

// CRUD

export const fbAdd = async (collection: string, fbdata: any) => {
	// fbdata.dateCreated = Date.now()
	const ref = await firebase.firestore().collection(collection).add(fbdata)

	return ref
}
export const fbUpdate = async (collection: string, uid: string, fbdata: any) => {
	const ref = await firebase.firestore().collection(collection).doc(uid).update(fbdata)

	return ref
}
export const fbSet = async (collection: string, uid: string, fbdata: any) => {
	const ref = await firebase.firestore().collection(collection).doc(uid).set(fbdata)

	return ref
}
export const fbDelete = async (collection: string, uid: string) => {
	const ref = await firebase.firestore().collection(collection).doc(uid).delete()

	return ref
}

// ETL

export const collectionToDataObject = (snapshot: firebase.firestore.QuerySnapshot) => {
	if (!snapshot) {
		return snapshot
	}

	const data: { [key: string]: any } = {}
	snapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot) => {
		data[doc.id] = doc.data()
	})
	return data
}
export const collectionToDataArray = (
	snapshot: firebase.firestore.QuerySnapshot,
	dataFilter?: (data: any) => boolean,
) => {
	if (!snapshot) {
		return snapshot
	}

	const data: any[] = []
	snapshot.forEach((doc: firebase.firestore.QueryDocumentSnapshot) => {
		const docData = doc.data()

		if (dataFilter) {
			if (!dataFilter(docData)) return
		}

		data.push({
			uid: doc.id,
			...docData,
		})
	})
	return data
}
export const docToDataObject = (doc: firebase.firestore.DocumentSnapshot) => {
	if (!doc || (doc && !doc.exists)) {
		return undefined
	}

	return doc.data() as any
}
export const docToDataObjectUid = (doc: firebase.firestore.DocumentSnapshot) => {
	if (!doc || (doc && !doc.exists)) {
		return undefined
	}

	return { uid: doc.id, ...doc.data() } as any
}
export const collectionToDataObjectAndArray = (snapshot: firebase.firestore.QuerySnapshot) => {
	if (!snapshot) {
		return snapshot
	}

	const array: any[] = []
	const object: { [key: string]: any } = {}
	snapshot.forEach((doc) => {
		const data = doc.data()
		array.push({
			uid: doc.id,
			...data,
		})
		object[doc.id] = data
	})
	return { object, array }
}

// batch request
interface BatchLoadData {
	collection: string
	uids: string[] | undefined
}
export const batchLoadListOfUIDs = async (data: BatchLoadData) => {
	const { collection, uids } = data
	if (!(collection && uids?.length)) {
		return
	}

	const requestArray = []
	let i = 0
	while (i < uids.length) {
		const nextUids = uids.slice(i, i + 10)
		// console.log({ nextUids })
		requestArray.push(
			firebase
				.firestore()
				.collection(collection)
				.where(firebase.firestore.FieldPath.documentId(), 'in', nextUids)
				.get(),
		)
		i += 10
	}
	const snapshotGroupArray = await Promise.all(requestArray)
	return snapshotGroupArray.reduce(
		(dataArray: any[], groupSnapshot) => [
			...dataArray,
			...collectionToDataArray(groupSnapshot),
		],
		[],
	)
}

export const getDocByUid = async (collection: string | undefined, uid: string | undefined) => {
	let doc: any = undefined
	if (!collection || !uid) {
		return doc
	}
	const fbData = await firebase
		.firestore()
		.collection(collection)
		.where(firebase.firestore.FieldPath.documentId(), '==', uid)
		.get()
	let dataArray = collectionToDataArray(fbData)
	if (dataArray.length === 1) {
		doc = dataArray[0]
	}
	return doc
}

// HELPERS

export const filterInactiveData = (d: any) => {
	// return true unless active field is false
	return !(d?.active === false)
}

//
// Convert Firebase Error Messages To Custom Ones
//
// export const determineAuthErrorMessage = (error) => {
// 	switch (error.code) {
// 		case 'auth/invalid-email':
// 			return {
// 				alertTitle: 'Invalid Email Format',
// 				alertMessage: 'Please provide a valid email address and try again.',
// 				alertIsVisible: true,
// 			}
// 		case 'auth/wrong-password':
// 			return {
// 				alertTitle: 'Invalid Password',
// 				alertMessage: 'Please provide a valid password and try again.',
// 				alertIsVisible: true,
// 			}
// 		case 'auth/network-request-failed':
// 			return {
// 				alertTitle: 'Network Request Failed',
// 				alertMessage: 'Please check your network connection and try again.',
// 				alertIsVisible: true,
// 			}
// 		case 'auth/user-not-found':
// 			return {
// 				alertTitle: 'Error Signing In',
// 				alertMessage: 'Please check your email and password and try again.',
// 				alertIsVisible: true,
// 			}
// 		case 'auth/email-already-in-use':
// 			return {
// 				alertTitle: 'Error Registering',
// 				alertMessage: 'There is already a user with that email address.',
// 				alertIsVisible: true,
// 			}
// 		case 'auth/invalid-action-code':
// 			return {
// 				alertTitle: 'Invalid Code',
// 				alertMessage: 'The code has either expired or already been used.',
// 				alertIsVisible: true,
// 			}
// 		default:
// 			console.log('Uncaught error', error)
// 			return {
// 				alertTitle: 'Authentication Failed',
// 				alertMessage: error.message,
// 				alertIsVisible: true,
// 			}
// 	}
// }
// export const determineForgotPasswordErrorMessage = (error) => {
// 	switch (error.code) {
// 		case 'auth/invalid-email':
// 			return {
// 				alertTitle: 'Invalid Email Format',
// 				alertMessage: 'Please provide a valid email address and try again.',
// 				alertIsVisible: true,
// 			}
// 		case 'auth/user-not-found':
// 			return {
// 				alertTitle: 'No Matching Email',
// 				alertMessage: 'Please check your email and try again.',
// 				alertIsVisible: true,
// 			}
// 		case 'auth/network-request-failed':
// 			return {
// 				alertTitle: 'Network Request Failed',
// 				alertMessage: 'Please check your network connection and try again.',
// 				alertIsVisible: true,
// 			}
// 		default:
// 			console.log('Uncaught error', error)
// 			return {
// 				alertTitle: 'Error Sending Email',
// 				alertMessage:
// 					'Failed to send password reset email. Check that you are entereing a valid email address and try again.',
// 				alertIsVisible: true,
// 			}
// 	}
// }
// export const determinePasswordResetErrorMessage = (error) => {
// 	switch (error.code) {
// 		case 'auth/weak-password':
// 			return {
// 				alertTitle: 'Invalid Password',
// 				alertMessage: 'Password must be at least 6 characters long.',
// 				alertIsVisible: true,
// 			}
// 		default:
// 			console.log('Uncaught error', error)
// 			return {
// 				alertTitle: 'Error Updating Password',
// 				alertMessage:
// 					'Check that your password is more then 6 characters and matches the confirm password.',
// 				alertIsVisible: true,
// 			}
// 	}
// }
