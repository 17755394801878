export const userPermissionArray = [
	{ key: 'supplies_admin', value: 'Depot Admin' },
	{ key: 'admin', value: 'Admin' },
	{ key: 'volunteer', value: 'Volunteer' },
	{ key: 'reporting', value: 'Reports' },
]

export const statusArray = [
	{ key: 1, value: 'active' },
	{ key: 2, value: 'break' },
	{ key: 3, value: 'inactive' },
]

export const programsArray = [{ key: 'bedside_magic', value: 'Bedside Magic' }]

export const sideNavLinks = [
	{
		links: [
			{
				title: 'Welcome',
				to: '/portal/welcome',
				activePath: '/portal/welcome',
				permissions: [],
				exact: true,
			},
		],
	},
	{
		sectionTitle: 'Magician',
		links: [
			{
				title: 'Log Visit',
				to: '/portal/log-magic',
				activePath: '/portal/log-magic',
				permissions: ['volunteer'],
			},
			{
				title: 'Calendar',
				to: '/portal/calendar',
				activePath: '/portal/calendar',
				permissions: ['volunteer'],
			},
			{
				title: 'Available Shifts',
				to: '/portal/codeblues',
				activePath: '/portal/codeblues',
				permissions: ['volunteer'],
			},
			{
				title: 'Supply Order',
				to: '/portal/supply-order',
				activePath: '/portal/supply-order',
				permissions: ['volunteer'],
			},
		],
	},
	{
		sectionTitle: 'Reports',
		links: [
			{
				title: 'Impact',
				to: '/portal/reports/impact/users',
				activePath: '/portal/reports/impact',
				permissions: ['admin', 'reporting'],
			},
			{
				title: 'Service Swaps',
				to: '/portal/reports/codeblue/users',
				activePath: '/portal/reports/codeblue',
				permissions: ['admin', 'reporting'],
			},
		],
	},
	{
		sectionTitle: 'Admin',
		links: [
			{
				title: 'Users',
				to: '/admin/users',
				activePath: '/admin/users',
				permissions: ['admin'],
			},
			{
				title: 'Hospitals',
				to: '/admin/hospitals',
				activePath: '/admin/hospitals',
				permissions: ['admin'],
			},
			{
				title: 'Supply Depots',
				to: '/admin/supplies/depots',
				activePath: '/admin/supplies',
				permissions: ['supplies_admin'],
			},
			{
				title: 'Announcements',
				to: '/admin/announcements',
				activePath: '/admin/announcements',
				permissions: ['admin'],
			},
			{
				title: 'Regions',
				to: '/admin/regions',
				activePath: '/admin/regions',
				permissions: ['admin'],
			},
		],
	},
]
